import React, { useState } from "react";

function FileDnD(props) {
  const [files, setFiles] = useState([]);
  const clearState = () => {
    console.log("Here");
    setFiles([]);
  };
  // pass the clearState function back to parent
  props.passClearStateFunc(clearState);

  const dataFileDnD = () => {
    return {
      files,
      addFiles: (event) => {
        setFiles([]);
        let tempFileURL = URL.createObjectURL(event.target.files[0]);
        props.onUpdate(tempFileURL, event.target.files[0]);
        setFiles(Array.from(event.target.files));
      },
      remove: () => {
        setFiles([]);
        props.onUpdate(
          "https://cdn.dribbble.com/users/55871/screenshots/2158022/media/8f2a4a2c9126a9f265fb9e1023b1698a.jpg?compress=1&resize=400x300",
          null
        );
      },
      dragstart: (event) => {
        event.preventDefault();
      },
      handleDrop: (e) => {
        e.preventDefault();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          let tempFileURL = URL.createObjectURL(e.dataTransfer.files[0]);
          props.onUpdate(tempFileURL, e.dataTransfer.files[0]);
          setFiles(Array.from(e.dataTransfer.files));
        }
      },
    };
  };

  const fileElements = files.map((file, index) => {
    return (
      <div
        key={index}
        className="relative flex flex-col items-center overflow-hidden text-center bg-gray-100 border rounded cursor-move select-none"
        style={{ paddingTop: "100%" }}
        onDragStart={(e) => dataFileDnD().dragstart(e)}
        onDragEnd={(e) => dataFileDnD().dragend(e)}
        draggable
        data-index={index}
      >
        <button
          className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none"
          type="button"
          onClick={() => dataFileDnD().remove()}
        >
          <svg
            className="w-4 h-4 text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
        <img
          className="absolute inset-0 z-0 object-cover w-full h-full border-4 border-white preview"
          src={URL.createObjectURL(file)}
          alt={file.name}
          draggable
          onDragStart={(e) => dataFileDnD().dragstart(e)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={(e) => dataFileDnD().dragend(e)}
          onDrop={(e) => dataFileDnD().handleDrop(e)}
          data-index={index}
        />
      </div>
    );
  });

  return (
    <div className="bg-white p7 rounded w-12/12 mx-auto">
      <div
        x-data={dataFileDnD()}
        className="relative flex flex-col p-4 text-gray-400 border border-gray-200 rounded"
      >
        <div
          x-ref="dnd"
          className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer"
          onDragOver={(e) => {
            e.preventDefault();
            e.currentTarget.classList.add("border-blue-400");
            e.currentTarget.classList.add("ring-4");
            e.currentTarget.classList.add("ring-inset");
          }}
          onDragLeave={(e) => {
            e.currentTarget.classList.remove("border-blue-400");
            e.currentTarget.classList.remove("ring-4");
            e.currentTarget.classList.remove("ring-inset");
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.currentTarget.classList.remove("border-blue-400");
            e.currentTarget.classList.remove("ring-4");
            e.currentTarget.classList.remove("ring-inset");
          }}
        >
          <input
            accept="*"
            type="file"
            className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
            onChange={(e) => dataFileDnD().addFiles(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.currentTarget.classList.add("border-blue-400");
              e.currentTarget.classList.add("ring-4");
              e.currentTarget.classList.add("ring-inset");
            }}
            onDragLeave={(e) => {
              e.currentTarget.classList.remove("border-blue-400");
              e.currentTarget.classList.remove("ring-4");
              e.currentTarget.classList.remove("ring-inset");
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.currentTarget.classList.remove("border-blue-400");
              e.currentTarget.classList.remove("ring-4");
              e.currentTarget.classList.remove("ring-inset");
              dataFileDnD().handleDrop(e);
            }}
            title=""
          />
          <div className="flex flex-col items-center justify-center py-10 text-center">
            <svg
              className="w-6 h-6 mr-1 text-current-50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <p className="m-0">
              Drag and Drop your image here or click to browse.
            </p>
            <p className="m-0">(PNG / JPG / JPEG).</p>
          </div>
        </div>
        <div x-if={files.length > 0} className="max-w-xs">
          <div className="mt-4 max-w-xss">{fileElements}</div>
        </div>
      </div>
    </div>
  );
}
export default FileDnD;
