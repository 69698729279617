import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Outlet />
      <footer class="p-4  F sm:p-6">
        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-gray-500 sm:text-center ">
            © 2023 NYC Bulletin Board™ All Rights Reserved.
          </span>
          <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a href="/createEvent" class="text-gray-500 hover:text-gray-900">
              <span class="">Add Pin</span>
            </a>
            <a href="/contact" class="text-gray-500 hover:text-gray-900">
              <span class="">Contact</span>
            </a>
            {/* <a
              href="/blog"
              class="text-gray-500 hover:text-gray-900 "
            >
              <span class="">Blog</span>
            </a> */}
            <a href="/feedback" class="text-gray-500 hover:text-gray-900">
              <span class="">Feedback</span>
            </a>
            <a
              href="mailto:contact@nycbullitonboard.com"
              class="text-gray-500 hover:text-gray-900"
            >
              <span class="">API Access</span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
