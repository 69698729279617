import React from "react";
import axios from "axios";
import FileDragAndDrop from "./FileDragAndDrop";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ErrorMessage } from "./Error";
import { SuccessMessageModal } from "./SubmissionSuccessfulModal";
import { Navigate } from "react-router-dom";

const API_CONNECTION_STR = process.env.REACT_APP_API_CONNECTION_STR;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

class CreateEvent extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      showSuccessMessageModal: false,
      showErrorMessage: false,
      checkBox: false,
      errorMessage: "",
      startDayTime: dayjs(),
      endDayTime: dayjs(),
      firstName: "",
      lastName: "",
      emailAddress: "",
      pinTitle: "",
      permaLink: "",
      description: "",
      image:
        "https://cdn.dribbble.com/users/55871/screenshots/2158022/media/8f2a4a2c9126a9f265fb9e1023b1698a.jpg?compress=1&resize=400x300",
      imageFile: null,
      streetAddress: "1 East 34th Street",
      zipCode: "",
      city: "",
      clearChildState: null,
      borough: "Manhattan",
    };
    this.datetimewidgetRef = React.createRef();
  }
  // this method assigns the child clearState function
  // to the parent reference
  assignClearChildState(childClearStateFunc) {
    // this.setState({ clearChildState: childClearStateFunc });
  }

  handleInputChange(event) {
    this.setState({ inputText: event.target.value });
    console.log(event.target.value);
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.setState({ userSubmittedSearchQuery: true });
    }
    console.log(this.state.inputText);
  }

  getBoroughEmoji(borough) {
    switch (borough) {
      case "Manhattan":
        return "🗽";
      case "Brooklyn":
        return "🌉";
      case "Queens":
        return "👑";
      case "Bronx":
        return "🏙️";
      case "Staten Island":
        return "🏝️";
      default:
        return "❓";
    }
  }
  trunc(limit, desc) {
    if (desc === "" || desc == null) {
      return "";
    }
    if (desc.length < limit) {
      return desc;
    }
    return desc.slice(0, limit) + "...";
  }
  uploadImageToCloudinary(file) {
    return new Promise((resolve) => {
      var url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload?upload_preset=ommpsol4`;
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText);
          // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
          var url = response.secure_url;
          resolve(url);

          // Create a thumbnail of the uploaded image, with 150px width
          // var tokens = url.split("/");
          // tokens.splice(-2, 0, "w_150,c_scale");
          // var img = new Image(); // HTML5 Constructor
          // img.src = tokens.join("/");
          // img.alt = response.public_id;
          // document.getElementById("gallery").appendChild(img);
        }
      };
      fd.append("file", file);
      xhr.send(fd);
    });
  }
  resetState = () => {
    this.setState({
      startDayTime: dayjs(),
      endDayTime: dayjs(),
      firstName: "",
      lastName: "",
      emailAddress: "",
      pinTitle: "",
      permaLink: "",
      description: "",
      image:
        "https://cdn.dribbble.com/users/55871/screenshots/2158022/media/8f2a4a2c9126a9f265fb9e1023b1698a.jpg?compress=1&resize=400x300",
      streetAddress: "1 East 34th Street",
      zipCode: "",
      city: "",
      company: "",
    });
    document.getElementById("default-checkbox").checked = false;
  };
  postForm(imageURL) {
    let payload = {
      startDayTime: this.state.startDayTime.toString(),
      endDayTime: this.state.endDayTime.toString(),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      pinTitle: this.state.pinTitle,
      permaLink: this.state.permaLink,
      description: this.state.description,
      image: imageURL,
      streetAddress: this.state.streetAddress,
      zipCode: this.state.zipCode,
      state: "New York",
      city: this.state.city,
      checkBox: false,
    };
    axios
      .post(API_CONNECTION_STR + "/event", payload)
      .then((response) => {
        if (response.data === 0) {
          this.resetState();
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.setState({
      showSuccessMessageModal: true,
    });
    this.clearChildState();
  }
  handleImage = (imageURL, imageFile) => {
    this.setState({ image: "" });
    this.setState({ image: imageURL });
    this.setState({ imageFile: imageFile });
  };
  validateForm = (e) => {
    e.preventDefault();
    // validation checks
    if (!this.state.firstName) {
      this.setState({
        errorMessage: "Please fill out the 'First Name' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.lastName) {
      this.setState({
        errorMessage: "Please fill out the 'Last Name' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.emailAddress) {
      this.setState({
        errorMessage: "Please fill out the 'Email' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.pinTitle) {
      this.setState({
        errorMessage: "Please fill out the 'Pin Title' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.permaLink) {
      this.setState({
        errorMessage: "Please fill out the 'Website' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.description) {
      this.setState({
        errorMessage: "Please fill out the 'Description' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.image) {
      this.setState({
        errorMessage: "Please fill add an Image.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.streetAddress) {
      this.setState({
        errorMessage: "Please fill out the 'Street Address' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.zipCode) {
      this.setState({
        errorMessage: "Please fill out the 'Zip Code' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.city) {
      this.setState({
        errorMessage: "Please fill out the 'City' Field.",
      });
      this.setState({ showErrorMessage: true });
    } else if (!this.state.checkBox) {
      this.setState({
        errorMessage:
          "Please check that all information is accurate and check the box at the bottom.",
      });
      this.setState({ showErrorMessage: true });
    } else {
      this.uploadImageToCloudinary(this.state.imageFile).then((imageURL) => {
        this.postForm(imageURL);
      });
    }
  };

  render() {
    return (
      <div>
        <nav class="flex flex-wrap justify-center sm:justify-between items-center">
          <div className="flex flex-wrap py-2 px-6">
            <div className="flex">
              <div className="flex-none">
                <a href="/">
                  <h1 class="pb-1 lg:pb-0 sm:px-8 text-3xl font-extrabold tracking-tight leading-none md:text-3xl lg:text-4xl dark:text-black">
                    NYC Bulletin Board
                  </h1>
                </a>
              </div>
              <div className="block sm:hidden px-10 grow">
                <a
                  href="/CreateEvent"
                  className=" rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <span class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        // transform="translate(8, 3) scale(0.7)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                        />
                        <path
                          d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
            <div class="align-top grow">
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="email-adress-icon"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2"
                  placeholder="Search..."
                  onKeyPress={(event) => this.handleInputChange(event)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                ></input>
                {this.state.userSubmittedSearchQuery && (
                  <Navigate
                    to="/search"
                    replace={true}
                    state={{ query: this.state.inputText }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            <div class="py-2 px-20 flex">
              <div className="px-2">
                <a
                  href="/contact"
                  className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <p
                    type="button"
                    class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                  >
                    Contact
                  </p>
                </a>
              </div>
              <div>
                <a
                  href="/CreateEvent"
                  className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <button
                    type="button"
                    class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                  >
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        // transform="translate(8, 3) scale(0.7)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                        />
                        <path
                          d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                        />
                      </g>
                    </svg>
                    Add a Pin
                  </button>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <hr class="mb-10 border-gray-200 sm:mx-auto lg:mt-1" />
        {this.state.showSuccessMessageModal && (
          <SuccessMessageModal message={"Form Submitted Successfully"} />
        )}
        {this.state.showErrorMessage && (
          <ErrorMessage message={this.state.errorMessage} />
        )}
        <div class="w-full max-w-7xl px-6 py-4 mx-auto">
          <form ref={this.formRef} onSubmit={this.validateForm}>
            <div class="mt-10 sm:mt-0">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Personal Information
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Let us know where we can update you once your pin is live.
                    </p>
                  </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="overflow-hidden shadow sm:rounded-md">
                    <div class="bg-white px-4 py-5 sm:p-6">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autocomplete="given-name"
                            value={this.state.firstName}
                            onChange={(e) =>
                              this.setState({ firstName: e.target.value })
                            }
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            value={this.state.lastName}
                            onChange={(e) =>
                              this.setState({ lastName: e.target.value })
                            }
                            autocomplete="family-name"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email-address"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <input
                            type="text"
                            name="email-address"
                            id="email-address"
                            autocomplete="email"
                            value={this.state.emailAddress}
                            onChange={(e) =>
                              this.setState({ emailAddress: e.target.value })
                            }
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>
                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email-address"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Company (Optional)
                          </label>
                          <input
                            type="text"
                            name="company"
                            id="company"
                            value={this.state.company}
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 text-right sm:px-6"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hidden sm:block" aria-hidden="true">
              <div class="py-5">
                <div class="border-t border-gray-200"></div>
              </div>
            </div>

            <div>
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Pin General Information
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      This information will be displayed publicly so be careful
                      what you share.
                    </p>
                  </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="shadow sm:overflow-hidden sm:rounded-md">
                    <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                      <div class="col-span-6">
                        <label
                          for="street-address"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Pin Title
                        </label>
                        <input
                          type="text"
                          name="street-address"
                          id="street-address"
                          autocomplete="street-address"
                          value={this.state.pinTitle}
                          onChange={(e) =>
                            this.setState({ pinTitle: e.target.value })
                          }
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></input>
                      </div>

                      <div class="grid grid-cols-3 gap-6">
                        <div class="col-span-3 sm:col-span-2">
                          <label
                            for="company-website"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Website (Where should we direct users when they
                            click on your pin?)<br></br>(Optional)
                          </label>
                          <div class="mt-1 flex rounded-md shadow-sm">
                            <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                              http://
                            </span>
                            <input
                              type="text"
                              name="company-website"
                              id="company-website"
                              value={this.state.permaLink}
                              onChange={(e) =>
                                this.setState({ permaLink: e.target.value })
                              }
                              class="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="www.example.com"
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div class="mt-1">
                          <textarea
                            id="Description"
                            name="Description"
                            rows="3"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder=""
                            value={this.state.description}
                            onChange={(e) =>
                              this.setState({ description: e.target.value })
                            }
                          ></textarea>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">
                          Brief description for your pin.
                        </p>
                      </div>
                      <FileDragAndDrop
                        onUpdate={this.handleImage}
                        passClearStateFunc={this.assignClearChildState}
                      ></FileDragAndDrop>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 text-right sm:px-6"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hidden sm:block" aria-hidden="true">
              <div class="py-5">
                <div class="border-t border-gray-200"></div>
              </div>
            </div>

            <div class="mt-10 sm:mt-0">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Time and Place
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      Tell people when and where to go.
                    </p>
                  </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="overflow-hidden shadow sm:rounded-md">
                    <div class="bg-white px-4 py-5 sm:p-6">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Start Date & Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              label=""
                              value={this.state.startDayTime}
                              onChange={(newValue) => {
                                this.setState({
                                  startDayTime: dayjs(newValue),
                                });
                              }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            End Date & Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              label=""
                              value={this.state.endDayTime}
                              onChange={(newValue) => {
                                this.setState({ endDayTime: dayjs(newValue) });
                              }}
                            />
                          </LocalizationProvider>
                        </div>

                        <div class="col-span-6">
                          <label
                            for="street-address"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Street address
                          </label>
                          <input
                            type="text"
                            name="street-address"
                            id="street-address"
                            autocomplete="street-address"
                            value={
                              this.state.streetAddress === "1 East 34th Street"
                                ? ""
                                : this.state.streetAddress
                            }
                            onChange={(e) =>
                              this.setState({ streetAddress: e.target.value })
                            }
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="country"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Borough
                          </label>
                          <select
                            id="country"
                            name="country"
                            autocomplete="country-name"
                            onChange={(e) =>
                              this.setState({ borough: e.target.value })
                            }
                            class="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="Manhattan">Manhattan 🗽</option>
                            <option value="Brooklyn">Brooklyn 🌉</option>
                            <option value="Queens">Queens 👑</option>
                            <option value="Bronx">Bronx 🏙️</option>
                            <option value="Staten Island">
                              Staten Island 🏝️
                            </option>
                            <option value="Other">Other ❓</option>
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label
                            for="city"
                            class="block text-sm font-medium text-gray-700"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            value={this.state.city}
                            onChange={(e) =>
                              this.setState({ city: e.target.value })
                            }
                            autocomplete="address-level2"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            for="region"
                            class="block text-sm font-medium text-gray-700"
                          >
                            State / Province
                          </label>
                          <input
                            type="text"
                            name="region"
                            id="region"
                            autocomplete="address-level1"
                            disabled={true}
                            value="New York :)"
                            class="text-gray-500 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label
                            for="postal-code"
                            class="block text-sm font-medium text-gray-700"
                          >
                            ZIP / Postal code
                          </label>
                          <input
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autocomplete="postal-code"
                            value={this.state.zipCode}
                            onChange={(e) =>
                              this.setState({ zipCode: e.target.value })
                            }
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 text-right sm:px-6"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hidden sm:block" aria-hidden="true">
              <div class="py-5">
                <div class="border-t border-gray-200"></div>
              </div>
            </div>

            <div class="mt-10 sm:mt-0">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Pin Preview
                    </h3>
                    <p class="mt-1 text-sm text-gray-600">
                      See what your pin will look like before.
                    </p>
                    <br></br>
                    <p class="mt-1 text-sm text-gray-600">
                      Note: All submissions are reviewed by our team prior to
                      publishing. Pins typically go live on the board within a
                      few hours of submission. We'll email you when your pin
                      goes live!
                    </p>
                    <div class="pt-7">
                      <div class="flex items-center mb-4">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value={this.state.checkBox}
                          onChange={(e) =>
                            this.setState({ checkBox: e.target.value })
                          }
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        ></input>
                        <label
                          for="default-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900"
                        >
                          I have checked all fields for accuracy.
                        </label>
                      </div>
                      <button
                        type="submit"
                        class="px-20 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-2.5 mr-2 mb-2"
                      >
                        Submit Pin
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                  <div class="flex flex-col items-center justify-center py-10">
                    <div className="max-w-md gap-2">
                      <article
                        className="overflow-x-hidden bg-white mt-4 ml-2 mr-2 shadow-lg border rounded-md duration-300 hover:shadow-sm"
                        key={event.id}
                      >
                        <a
                          href={event.perma_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            this.addUserClickToEvent(event);
                          }}
                        >
                          <img
                            src={this.state.image}
                            loading="lazy"
                            alt={event.name}
                            className="w-full h-48 rounded-t-md border-2 border-gray"
                          />
                          <div className="pt-1 ml-4">
                            <h3 className="text-xl text-gray-900">
                              {this.state.pinTitle}
                            </h3>
                          </div>
                        </a>
                        <div>
                          <div className="ml-3 mb-3 mt-1">
                            <span
                              style={{ backgroundColor: "white" }}
                              class="text-sm py-0.3125 px-1.5 text-black border-2 border-gray rounded-full"
                            >
                              {this.getBoroughEmoji(this.state.borough)}{" "}
                              {this.state.borough}
                            </span>
                          </div>
                          <div></div>
                          <span className="pt-1 ml-4 mr-2 mb-1 flex items-center text-gray-500">
                            <svg
                              class="icon-3wk-G mr-4"
                              viewBox="0 0 16 16"
                              version="1.1"
                              width="16"
                              height="16"
                              fill="currentColor"
                            >
                              <path d="M14.5,3.80960471 C14.5,3.3885806 14.16527,3.04761905 13.753683,3.04761905 C13.75,3.4684731 13.4171014,3.80952381 13,3.80952381 C12.5857864,3.80952381 12.25,3.47145398 12.25,3.04776572 L10.75,3.04761905 C10.75,3.4684731 10.4171014,3.80952381 10,3.80952381 C9.58578644,3.80952381 9.25,3.47145398 9.25,3.04776572 L7.75,3.04761905 C7.75,3.4684731 7.41710138,3.80952381 7,3.80952381 C6.58578644,3.80952381 6.25,3.47145398 6.25,3.04776572 L4.75,3.04761905 C4.75,3.4684731 4.41710138,3.80952381 4,3.80952381 C3.58578644,3.80952381 3.25,3.47145398 3.25,3.04776572 C2.83429619,3.04761905 2.5,3.38814731 2.5,3.80960471 L2.5,5.33333333 L14.5,5.33333333 L14.5,3.80960471 Z M2.5,6.85714286 L2.5,13.7142048 C2.5,14.1352289 2.83472997,14.4761905 3.24631697,14.4761905 L13.753683,14.4761905 C14.1657038,14.4761905 14.5,14.1356622 14.5,13.7142048 L14.5,6.85714286 L2.5,6.85714286 Z M16,3.80960471 L16,13.7142048 C16,14.9763117 14.9950464,16 13.753683,16 L3.24631697,16 C2.00539581,16 1,14.975887 1,13.7142048 L1,3.80960471 C1,2.54749779 2.00495356,1.52380952 3.24631697,1.52380952 L3.25,0.761758089 C3.25,0.341050713 3.58289862,0 4,0 C4.41421356,0 4.75,0.338069825 4.75,0.761758089 L4.75,1.52380952 L6.25,1.52380952 L6.25,0.761758089 C6.25,0.341050713 6.58289862,0 7,0 C7.41421356,0 7.75,0.338069825 7.75,0.761758089 L7.75,1.52380952 L9.25,1.52380952 L9.25,0.761758089 C9.25,0.341050713 9.58289862,0 10,0 C10.4142136,0 10.75,0.338069825 10.75,0.761758089 L10.75,1.52380952 L12.25,1.52380952 L12.25,0.761758089 C12.25,0.341050713 12.5828986,0 13,0 C13.4142136,0 13.75,0.338069825 13.75,0.761758089 L13.75,1.52380952 C14.9946042,1.52380952 16,2.54792253 16,3.80960471 Z"></path>
                            </svg>
                            <span className=" text-sm">
                              {this.state.startDayTime.toString()}
                            </span>
                          </span>
                          {event.address !== "" && (
                            <div className="pt-1 ml-4 mr-2 mb-1">
                              <div className="">
                                <span className="flex items-center text-gray-500">
                                  <svg
                                    class="icon-3wk-G mr-4"
                                    viewBox="0 0 16 16"
                                    version="1.1"
                                    width="16"
                                    height="16"
                                  >
                                    <path
                                      d="M10.972872,12.2628962 C9.89422319,13.5430832 8.53479398,14.6666667 8,14.6666667 C7.46520602,14.6666667 6.10577681,13.5430832 5.02712798,12.2628962 C3.56323692,10.525487 2.66666667,8.6142939 2.66666667,6.66666667 C2.66666667,3.721148 5.05448133,1.33333333 8,1.33333333 C10.9455187,1.33333333 13.3333333,3.721148 13.3333333,6.66666667 C13.3333333,8.6142939 12.4367631,10.525487 10.972872,12.2628962 Z M7.54962119,12.9027992 C7.74740822,13.0662724 8,13.2495838 8,13.2495838 C8,13.2495838 8.28091752,13.0428609 8.45037881,12.9027992 C8.94213799,12.4963548 9.47059939,11.9765709 9.95322457,11.4037705 C11.2328697,9.88503235 12,8.24976273 12,6.66666667 C12,4.45752767 10.209139,2.66666667 8,2.66666667 C5.790861,2.66666667 4,4.45752767 4,6.66666667 C4,8.24976273 4.7671303,9.88503235 6.04677543,11.4037705 C6.52940061,11.9765709 7.05786201,12.4963548 7.54962119,12.9027992 Z M6,6.66666667 C6,5.56209717 6.8954305,4.66666667 8,4.66666667 C9.1045695,4.66666667 10,5.56209717 10,6.66666667 C10,7.77123617 9.1045695,8.66666667 8,8.66666667 C6.8954305,8.66666667 6,7.77123617 6,6.66666667 Z M8.66666667,6.66666667 C8.66666667,6.29847683 8.36818983,6 8,6 C7.63181017,6 7.33333333,6.29847683 7.33333333,6.66666667 C7.33333333,7.0348565 7.63181017,7.33333333 8,7.33333333 C8.36818983,7.33333333 8.66666667,7.0348565 8.66666667,6.66666667 Z"
                                      id="Combined-Shape"
                                      fill-rule="nonzero"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  {this.trunc(39, this.state.streetAddress)}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="pt-1 ml-4 mr-2 mb-1 pb-2">
                          {!(
                            this.state.price == -1 ||
                            this.state.price == null ||
                            this.state.price == 0
                          ) && (
                            <a
                              href={this.state.permaLink}
                              className="mt-3 mb-3"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button
                                style={{ backgroundColor: "#7EB2DD" }}
                                class="px-4 py-1 font-small tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                              >
                                Starting at ${this.state.price}
                              </button>
                            </a>
                          )}
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateEvent;
