import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorMessage } from "./Error";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";

const API_CONNECTION_STR = process.env.REACT_APP_API_CONNECTION_STR;

const Search = () => {
  let location = useLocation();

  const [inputText, setInputText] = useState(null);
  const [displayedText, setDisplayedText] = useState(null);

  const [filteredResults, setFilteredResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [emptyResults, setEmptyResults] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    console.log("hEre   ");
    axios
      .post(API_CONNECTION_STR + "/searchForTerm", {
        searchTerm: location.state.query,
      })
      .then((res) => {
        let tmp = res.data;
        setFilteredResults(res.data.dataArr);
        setResultsCount(res.data.count);
        setDisplayedText(location.state.query);
        setIsLoading(false);
      })
      .finally(() => {})
      .catch((res) => {
        alert(res);
      });
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      axios
        .post(API_CONNECTION_STR + "/searchForTerm", {
          searchTerm: inputText,
        })
        .then((res) => {
          let tmp = res.data;
          setFilteredResults(res.data.dataArr);
          setResultsCount(res.data.count);
          setIsLoading(false);
          console.log("Getting hit?", inputText);

          setDisplayedText(inputText);
        })
        .finally(() => {})
        .catch((res) => {
          alert(res);
        });
    }
  };

  const getBoroughEmoji = (borough) => {
    switch (borough) {
      case "Manhattan":
        return "🗽";
      case "Brooklyn":
        return "🌉";
      case "Queens":
        return "👑";
      case "Bronx":
        return "🏙️";
      case "Staten Island":
        return "🏝️";
      default:
        return "❓";
    }
  };

  const trunc = (limit, desc) => {
    if (desc === "" || desc == null) {
      return "";
    }
    if (desc.length < limit) {
      return desc;
    }
    return desc.slice(0, limit) + "...";
  };

  const addUserClickToEvent = (e) => {
    axios
      .post(API_CONNECTION_STR + "/addLikeToPin", {
        id: event.id,
      })
      .catch((res) => {
        alert(res);
      });
  };

  return (
    <div className="min-h-screen">
      <nav class="flex flex-wrap justify-center sm:justify-between items-center">
        <div className="flex flex-wrap py-2 px-6">
          <div className="flex">
            <div className="flex-none">
              <a href="/">
                <h1 class="pb-1 lg:pb-0 sm:px-8 text-3xl font-extrabold tracking-tight leading-none md:text-3xl lg:text-4xl dark:text-black">
                  NYC Bulletin Board
                </h1>
              </a>
            </div>
            <div className="block sm:hidden pl-10 grow">
              <a
                href="/CreateEvent"
                className=" rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
              >
                <span class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      // transform="translate(8, 3) scale(0.7)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                      />
                      <path
                        d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                      />
                    </g>
                  </svg>
                </span>
              </a>
            </div>
          </div>
          <div class="align-top grow">
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="email-adress-icon"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2"
                placeholder="Search..."
                value={inputText}
                onChange={(event) => setInputText(event.target.value)}
                onKeyDown={handleKeyDown.bind(this)}
              ></input>
              {/* {this.state.userSubmittedSearchQuery && (
                  <Navigate
                    to="/search"
                    replace={true}
                    state={{ query: this.state.inputText }}
                  />
                )} */}
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <div class="py-2 px-20 flex">
            <div className="px-2">
              <a
                href="/contact"
                className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
              >
                <p
                  type="button"
                  class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                >
                  Contact
                </p>
              </a>
            </div>
            <div>
              <a
                href="/CreateEvent"
                className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
              >
                <button
                  type="button"
                  class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                >
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      // transform="translate(8, 3) scale(0.7)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                      />
                      <path
                        d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                      />
                    </g>
                  </svg>
                  Add a Pin
                </button>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <hr class="border-gray-200 sm:mx-auto" />

      <section className="mt-1 mx-auto max-w-screen-xl lg:px-8">
        <div className="text-center mx-auto">
          <h1 className="text-2.25xl pt-5 text-gray-800 font-semibold sm:text-4xl">
            Search Results for '{displayedText}'
          </h1>
        </div>
        {resultsCount == 0 ? (
          <div className="flex justify-center content-center">
            <div className="border border-2  rounded-lg px-4 py-6  my-20 w-3/5 mx-3 text-center">
              <h1 className="text-2xl text-gray-500">
                We didn't find any pins that matched your search
              </h1>
              <h1 className="text-2xl text-gray-500">Check back later</h1>
            </div>
          </div>
        ) : (
          <div>
            {isLoading && (
              <div className="mt-5 grid gap-2 sm:grid-cols-2 lg:grid-cols-3 pl-1.5 pr-1.5">
                {emptyResults.map((event) => (
                  <article
                    className="overflow-x-hidden bg-white mt-4 ml-2 mr-2 shadow-lg border rounded-md duration-300 hover:shadow-sm"
                    key={event.id}
                  >
                    <div className="w-full h-48 rounded-t-md border-2 border-gray bg-gray-200"></div>
                    <div className="pt-1 ml-4">
                      <h3 className="text-xl text-gray-900">{event.name}</h3>
                    </div>
                    <div>
                      <div></div>
                      <div class="bg-gray-200 h-4 w-3/4 ml-4 my-2 rounded-md"></div>
                      <div class="bg-gray-200 h-4 w-3/4 ml-4 my-2 rounded-md"></div>
                    </div>
                    <div className="ml-3 mb-3 mt-2">
                      <span class="text-sm py-0.3125 px-6 text-black border-2 border-gray bg-gray-200 rounded-full"></span>

                      <span class="text-sm py-0.3125 px-6 text-black border-2 border-gray bg-gray-200 rounded-full mx-2 "></span>
                    </div>
                    <div className="py-12"></div>
                  </article>
                ))}
              </div>
            )}
            {!isLoading && (
              <div className="mt-5 grid gap-2 sm:grid-cols-2 lg:grid-cols-3 pl-1.5 pr-1.5">
                {filteredResults.map((event) => (
                  <article
                    className="overflow-x-hidden bg-white mt-4 ml-2 mr-2 shadow-lg border rounded-md duration-300 hover:shadow-sm"
                    key={event.id}
                  >
                    <a
                      href={event.perma_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        addUserClickToEvent(event);
                      }}
                    >
                      <div className="h-48 overflow-hidden">
                        <img
                          src={event.event_image}
                          loading="lazy"
                          alt={event.name}
                          className="w-full h-48 rounded-t-md border-2 border-gray transition duration-300 transform sm:hover:scale-110"
                        />
                      </div>
                      <div className="pt-1 ml-4">
                        <h3 className="text-xl text-gray-900">{event.name}</h3>
                      </div>
                    </a>
                    <div>
                      <div className="ml-3 mb-3 mt-1">
                        <span
                          style={{ backgroundColor: "white" }}
                          class="text-sm py-0.3125 px-1.5 text-black border-2 border-gray rounded-full"
                        >
                          {getBoroughEmoji(event.borough)} {event.borough}
                        </span>

                        {event.source === "Ticketmaster" ? (
                          <span class="ml-1 text-sm py-0.3125 px-1.5 text-black border-2 border-gray rounded-full">
                            💰 Paid
                          </span>
                        ) : (
                          <span class="ml-1 text-sm py-0.3125 px-1.5 text-black border-2 border-gray rounded-full">
                            🆓 Free
                          </span>
                        )}
                      </div>
                      <div></div>
                      <span className="pt-1 ml-4 mr-2 mb-1 flex items-center text-gray-500">
                        <svg
                          class="icon-3wk-G mr-4"
                          viewBox="0 0 16 16"
                          version="1.1"
                          width="16"
                          height="16"
                          fill="currentColor"
                        >
                          <path d="M14.5,3.80960471 C14.5,3.3885806 14.16527,3.04761905 13.753683,3.04761905 C13.75,3.4684731 13.4171014,3.80952381 13,3.80952381 C12.5857864,3.80952381 12.25,3.47145398 12.25,3.04776572 L10.75,3.04761905 C10.75,3.4684731 10.4171014,3.80952381 10,3.80952381 C9.58578644,3.80952381 9.25,3.47145398 9.25,3.04776572 L7.75,3.04761905 C7.75,3.4684731 7.41710138,3.80952381 7,3.80952381 C6.58578644,3.80952381 6.25,3.47145398 6.25,3.04776572 L4.75,3.04761905 C4.75,3.4684731 4.41710138,3.80952381 4,3.80952381 C3.58578644,3.80952381 3.25,3.47145398 3.25,3.04776572 C2.83429619,3.04761905 2.5,3.38814731 2.5,3.80960471 L2.5,5.33333333 L14.5,5.33333333 L14.5,3.80960471 Z M2.5,6.85714286 L2.5,13.7142048 C2.5,14.1352289 2.83472997,14.4761905 3.24631697,14.4761905 L13.753683,14.4761905 C14.1657038,14.4761905 14.5,14.1356622 14.5,13.7142048 L14.5,6.85714286 L2.5,6.85714286 Z M16,3.80960471 L16,13.7142048 C16,14.9763117 14.9950464,16 13.753683,16 L3.24631697,16 C2.00539581,16 1,14.975887 1,13.7142048 L1,3.80960471 C1,2.54749779 2.00495356,1.52380952 3.24631697,1.52380952 L3.25,0.761758089 C3.25,0.341050713 3.58289862,0 4,0 C4.41421356,0 4.75,0.338069825 4.75,0.761758089 L4.75,1.52380952 L6.25,1.52380952 L6.25,0.761758089 C6.25,0.341050713 6.58289862,0 7,0 C7.41421356,0 7.75,0.338069825 7.75,0.761758089 L7.75,1.52380952 L9.25,1.52380952 L9.25,0.761758089 C9.25,0.341050713 9.58289862,0 10,0 C10.4142136,0 10.75,0.338069825 10.75,0.761758089 L10.75,1.52380952 L12.25,1.52380952 L12.25,0.761758089 C12.25,0.341050713 12.5828986,0 13,0 C13.4142136,0 13.75,0.338069825 13.75,0.761758089 L13.75,1.52380952 C14.9946042,1.52380952 16,2.54792253 16,3.80960471 Z"></path>
                        </svg>
                        <span className=" text-sm">
                          {event.event_date}

                          {!(
                            event.event_time == null ||
                            event.event_time === "" ||
                            event.event_date === "Multiple Dates"
                          ) && <span> • {event.event_time}</span>}
                        </span>
                      </span>
                      {event.address !== "" && (
                        <div className="pt-1 ml-4 mr-2 mb-1">
                          <div className="">
                            <span className="flex items-center text-gray-500">
                              <svg
                                class="icon-3wk-G mr-4"
                                viewBox="0 0 16 16"
                                version="1.1"
                                width="16"
                                height="16"
                              >
                                <path
                                  d="M10.972872,12.2628962 C9.89422319,13.5430832 8.53479398,14.6666667 8,14.6666667 C7.46520602,14.6666667 6.10577681,13.5430832 5.02712798,12.2628962 C3.56323692,10.525487 2.66666667,8.6142939 2.66666667,6.66666667 C2.66666667,3.721148 5.05448133,1.33333333 8,1.33333333 C10.9455187,1.33333333 13.3333333,3.721148 13.3333333,6.66666667 C13.3333333,8.6142939 12.4367631,10.525487 10.972872,12.2628962 Z M7.54962119,12.9027992 C7.74740822,13.0662724 8,13.2495838 8,13.2495838 C8,13.2495838 8.28091752,13.0428609 8.45037881,12.9027992 C8.94213799,12.4963548 9.47059939,11.9765709 9.95322457,11.4037705 C11.2328697,9.88503235 12,8.24976273 12,6.66666667 C12,4.45752767 10.209139,2.66666667 8,2.66666667 C5.790861,2.66666667 4,4.45752767 4,6.66666667 C4,8.24976273 4.7671303,9.88503235 6.04677543,11.4037705 C6.52940061,11.9765709 7.05786201,12.4963548 7.54962119,12.9027992 Z M6,6.66666667 C6,5.56209717 6.8954305,4.66666667 8,4.66666667 C9.1045695,4.66666667 10,5.56209717 10,6.66666667 C10,7.77123617 9.1045695,8.66666667 8,8.66666667 C6.8954305,8.66666667 6,7.77123617 6,6.66666667 Z M8.66666667,6.66666667 C8.66666667,6.29847683 8.36818983,6 8,6 C7.63181017,6 7.33333333,6.29847683 7.33333333,6.66666667 C7.33333333,7.0348565 7.63181017,7.33333333 8,7.33333333 C8.36818983,7.33333333 8.66666667,7.0348565 8.66666667,6.66666667 Z"
                                  id="Combined-Shape"
                                  fill-rule="nonzero"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              {trunc(39, event.address)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="pt-1 ml-4 mr-2 mb-1 pb-2">
                      {!(
                        event.price == -1 ||
                        event.price == null ||
                        event.price == 0
                      ) && (
                        <a
                          href={event.perma_link}
                          className="mt-3 mb-3"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button class="bg-offBlue px-4 py-1 font-small tracking-wide text-white capitalize transition-colors duration-300 transform hover:bg-offDarkBlue rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                            Starting at ${event.price}
                          </button>
                        </a>
                      )}
                      {/* event.description sometimes takes up too much room */}
                      <div className="max-w-xs">
                        {/* <p className="text-gray-400 text-sm">
                      {this.trunc(event.description)}
                    </p> */}
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default Search;
