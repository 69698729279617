import React from "react";
import axios from "axios";
import { ErrorMessage } from "./Error";
import { SuccessMessage } from "./SubmissionSuccessful";
import { Navigate } from "react-router-dom";

const API_CONNECTION_STR = process.env.API_CONNECTION_STR;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  }
  handleInputChange(event) {
    this.setState({ inputText: event.target.value });
    console.log(event.target.value);
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.setState({ userSubmittedSearchQuery: true });
    }
    console.log(this.state.inputText);
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      message: event.target.elements.message.value,
    };
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      this.setState({
        showErrorMessage: true,
      });
      return;
    }
    axios
      .post(API_CONNECTION_STR + "/submitFeedback", formData)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
    this.setState({
      showSuccessMessage: true,
    });
    this.formRef.current.reset();
  };

  render() {
    return (
      <div>
        <nav class="flex flex-wrap justify-center sm:justify-between items-center">
          <div className="flex flex-wrap py-2 px-6">
            <div className="flex">
              <div className="flex-none">
                <a href="/">
                  <h1 class="pb-1 lg:pb-0 sm:px-8 text-3xl font-extrabold tracking-tight leading-none md:text-3xl lg:text-4xl dark:text-black">
                    NYC Bulletin Board
                  </h1>
                </a>
              </div>
              <div className="block sm:hidden px-10 grow">
                <a
                  href="/CreateEvent"
                  className=" rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <span class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        // transform="translate(8, 3) scale(0.7)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                        />
                        <path
                          d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
            <div class="align-top grow">
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="email-adress-icon"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2"
                  placeholder="Search..."
                  onKeyPress={(event) => this.handleInputChange(event)}
                  onKeyDown={this.handleKeyDown.bind(this)}
                ></input>
                {this.state.userSubmittedSearchQuery && (
                  <Navigate
                    to="/search"
                    replace={true}
                    state={{ query: this.state.inputText }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            <div class="py-2 px-20 flex">
              <div className="px-2">
                <a
                  href="/contact"
                  className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <p
                    type="button"
                    class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                  >
                    Contact
                  </p>
                </a>
              </div>
              <div>
                <a
                  href="/CreateEvent"
                  className="inline-block rounded hover:border-gray-200 hover:bg-gray-200 py-1 "
                >
                  <button
                    type="button"
                    class=" font-bold text-lg text-black font-medium py-1  sm:mx-2 rounded-lg inline-flex items-center"
                  >
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        // transform="translate(8, 3) scale(0.7)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1490 4481 l0 -209 -252 -4 c-229 -3 -259 -6 -311 -25 -115 -43 -214
-138 -258 -250 l-24 -58 0 -1585 c0 -1510 1 -1587 18 -1637 41 -117 146 -220
270 -263 56 -20 83 -20 1625 -20 1744 0 1614 -5 1730 69 71 47 141 135 169
214 17 50 18 127 18 1637 l0 1585 -24 58 c-44 112 -143 207 -258 250 -52 19
-82 22 -310 25 l-253 4 0 209 0 209 -215 0 -215 0 0 -210 0 -210 -640 0 -640
0 0 210 0 210 -215 0 -215 0 0 -209z m2560 -2351 l0 -1280 -1490 0 -1490 0 0
1280 0 1280 1490 0 1490 0 0 -1280z"
                        />
                        <path
                          d="M2350 2670 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                        />
                      </g>
                    </svg>
                    Add a Pin
                  </button>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <hr class="mb-12 border-gray-200 sm:mx-auto lg:mt-2" />
        {this.state.showSuccessMessage && (
          <SuccessMessage message={"Form Submitted Successfully"} />
        )}
        {this.state.showErrorMessage && (
          <ErrorMessage message={"Please Complete All Fields"} />
        )}
        <div className="w-full max-w-2xl px-6 py-10 mx-auto border rounded-lg">
          <form ref={this.formRef} onSubmit={this.handleSubmit}>
            <section class="px-6 py-12">
              <h2 class="text-3xl font-semibold text-center text-gray-800">
                Feedback Form
              </h2>

              <p class="mt-3 text-center text-gray-600">
                Have feedback to improve our product or have a bug to report?
              </p>

              <div class="mt-6 ">
                <div class="items-center -mx-2 md:flex">
                  <div class="w-full mx-2">
                    <label class="block mb-2 text-sm font-medium text-gray-600">
                      Name
                    </label>

                    <input
                      class="block w-full px-4 py-2 text-gray-700 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      type="text"
                      name="name"
                    ></input>
                  </div>

                  <div class="w-full mx-2 mt-4 md:mt-0">
                    <label class="block mb-2 text-sm font-medium text-gray-600">
                      E-mail
                    </label>

                    <input
                      class="block w-full px-4 py-2 text-gray-700 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      type="email"
                      name="email"
                    ></input>
                  </div>
                </div>

                <div class="w-full mt-4">
                  <label class="block mb-2 text-sm font-medium text-gray-600">
                    Message
                  </label>

                  <textarea
                    name="message"
                    class="block w-full h-40 px-4 py-2 text-gray-700 bg-gray border rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  ></textarea>
                </div>

                <div class="flex justify-center mt-6">
                  <button
                    type="submit"
                    class="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    );
  }
}

export default Feedback;
